import {ChangeDetectorRef, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { BASE_URL } from '../consts';
import {
  UsernameAvailableResponse,
  SignInCredentials,
  User
} from '../models';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  signedin$ = new BehaviorSubject(null);
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  usernameAvailable( username: string) {
    return this.http.post<UsernameAvailableResponse>(`${BASE_URL}/auth/username`, {
      username
    });
  }

  signin(credentials: SignInCredentials) {
    return this.http.post<any>(`${BASE_URL}/wp-json/jwt-auth/v1/token`, credentials)
      .pipe(
        tap((response) => {
          if (response.allowed) {
            localStorage.setItem('currentUser', JSON.stringify(response));
            this.signedin$.next(true);
            this.currentUserSubject.next(response);
            return response;
          }
          return throwError(new Error('User not allowed'));
        })
      );
  }

  checkAuth() {

    const user = this.currentUserSubject.value;
    if (user && user.token) {
      const result = this.http.post<any>(`${BASE_URL}/wp-json/jwt-auth/v1/token/validate`, {})
        .pipe(
          tap((response) => {
            return response;
          }),
          catchError(this.handleError)
        );

      result.subscribe((res) => {
        if (res && res.code && res.code === 'jwt_auth_valid_token') {
          this.signedin$.next(true);
        } else {
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
          this.signedin$.next(false);
        }
      },
        (err) => {
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
          this.signedin$.next(false);
      });
    } else {
      this.signedin$.next(false);
    }
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error.message || 'Error validating');
  }

  signout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.signedin$.next(null);
  }
}
