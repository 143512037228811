import {AfterViewChecked, ChangeDetectorRef, Component, OnChanges, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {BehaviorSubject} from 'rxjs';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked{
  signedin$: BehaviorSubject<boolean>;
  username = '';
  d = new Date();
  constructor(private authService: AuthService, public router: Router, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {
    this.signedin$ = this.authService.signedin$;
  }

  ngOnInit() {
    this.authService.checkAuth();
    if (this.signedin$) {
      this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          if(evt.url && evt.url === '/') {
            this.router.navigateByUrl('/orders');
          }
          // trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
          // if you need to scroll back to top, here is the right place
          window.scrollTo(0, 0);
        }
      });
      // this.router.navigateByUrl('/orders');
      const user = JSON.parse(localStorage.getItem('currentUser'));
      if(user && user.hasOwnProperty('user_email')){
        this.username = user.user_email;
      }
    } else {
      this.router.navigateByUrl('/signin');
    }
  }

  ngAfterViewChecked() {
    this.signedin$.subscribe((value) => {
      if (value === null) {
        this.cdr.detectChanges();
      } else {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        if (user && user.hasOwnProperty('user_email')) {
          if (this.username !== user.user_email) {
            this.username = user.user_email;
          }
        }
      }
    });
  }
}
