import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router ) { }

  ngOnInit() {
    this.authService.signout();
    this.router.navigateByUrl('/signin');
  }
}
