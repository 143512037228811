import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'printVariations'
})
export class PrintVariationsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === '') {
      return 'No Variations';
    }

    if (parseInt(value, 10) === -1){
      return 'Required Product';
    }
    const values = JSON.parse(value);
    let attributes = '';
    Object.keys(values).forEach((key) => {
      const valuesItems = values[key].split(':');
      attributes += '<p>' + '<strong>' + valuesItems[0].replace('-', ' ') + '</strong> :' + valuesItems[1] +  '</p>';
    });


    return attributes;
    // return values;
  }

}
