import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './auth/auth.guard';

const routes: Routes = [
  {
    canLoad: [AuthGuard],
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
  },
  {
    canLoad: [AuthGuard],
    path: 'suppliers',
    loadChildren: () => import('./suppliers/suppliers.module').then(m => m.SuppliersModule)
  },
  {
    canLoad: [AuthGuard],
    path: 'suppliers-orders',
    loadChildren: () => import('./suppliers-orders/suppliers-orders.module').then(m => m.SuppliersOrdersModule)
  },
  {
    canLoad: [AuthGuard],
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
  },
  {
    canLoad: [AuthGuard],
    path: 'stock-products',
    loadChildren: () => import('./stock-products/stock-products.module').then(m => m.StockProductsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
