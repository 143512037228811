import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import {ReactiveFormsModule} from '@angular/forms';
import { PrintVariationsPipe } from './pipes/print-variations.pipe';
import { ModalComponent } from './modal/modal.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RemoveDashPipe } from './pipes/remove-dash.pipe'; // <-- import the module

@NgModule({
  declarations: [InputComponent, PrintVariationsPipe, ModalComponent, RemoveDashPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [
    InputComponent,
    PrintVariationsPipe,
    ModalComponent,
    NgxPaginationModule,
    RemoveDashPipe
  ]
})
export class SharedModule { }
