<div class="ui middle aligned center aligned grid container">
  <div class="column">
    <h2 class="ui dark-blue image header">
      <!--      <img src="assets/images/logo.png" class="image">-->
      <div class="content">
        Sign in to your account
      </div>
    </h2>
<form (ngSubmit)="onSubmit()" class="ui form" [formGroup]="authForm">
  <div class="ui stacked segment">
    <app-input type="text" label="Username" [control]="authForm.get('username')"></app-input>
    <app-input type="password"  label="Password" [control]="authForm.get('password')"></app-input>
    <div *ngIf="authForm.get('password').touched && authForm.errors" class="ui red basic label">
      <p *ngIf="authForm.errors?.noConnection">
        No internet connection - Please try again later
      </p>
      <p *ngIf="authForm.errors?.noCORS">
        VPN auth issue - Please try again later
      </p>
      <p *ngIf="authForm.errors?.invalidRole">
        Role not allowed
      </p>
      <p *ngIf="authForm.errors?.unknownError">
        Cannot connect to service - Please try again later
      </p>
      <p *ngIf="authForm.errors?.wrongCredentials">
        Invalid username or password
      </p>
    </div>
    <div>
      <button *ngIf="!onAuthLoading" class="ui submit button primary">Submit</button>
      <button disabled *ngIf="onAuthLoading" class="ui submit loading button primary">Submit</button>
    </div>
  </div>
</form>


<!--    <div class="ui message">-->
<!--      Scotclans-->
<!--    </div>-->
  </div>
</div>
