  <div class="ui secondary pointing menu fixed-menu over-all">
    <a href="/orders" class="item"><img src="../assets/icons/logo.png" ></a>
    <div class="right menu">
      <ng-container *ngIf="signedin$ | async">
<!--        <a routerLink="/orders" class="ui item" routerLinkActive="active">Orders</a>-->
<!--        <a routerLink="/signout" class="ui item" routerLinkActive="active">Sign out</a>-->
        <span *ngIf="username !== ''" class="header-title-centered">Logged in as {{ username }}</span><a *ngIf="username !== ''"  routerLink="/logout" class="ui item" routerLinkActive="active"><i class=" sign-out icon"></i></a>
        <span *ngIf="username === ''" class="header-title-centered">Welcome</span><a *ngIf="username === ''"  routerLink="/logout" class="ui item" routerLinkActive="active"><i class=" sign-out icon"></i></a>
      </ng-container>
<!--      <ng-container *ngIf="!(signedin$ | async)">-->
<!--        <a-->
<!--          routerLink="/signin" class="ui item"-->
<!--          routerLinkActive="active"-->
<!--          [routerLinkActiveOptions]="{ exact: true}"-->
<!--        >Sign in</a>-->
<!--        <a routerLink="/signup" class="ui item" routerLinkActive="active">Sign up</a>-->
<!--      </ng-container>-->
    </div>
  </div>
  <div class="ui left demo vertical inverted labeled icon sidebar menu uncover visible top-menu">

    <ng-container *ngIf="signedin$ | async">
<!--      <a routerLink="/orders" class="item red" routerLinkActive="active">-->
<!--        <i class="home icon"></i>-->
<!--        Home-->
<!--      </a>-->
      <a
        routerLink="orders" class="item"
        [class.active]="router.isActive('/orders', false)"
        routerLinkActive="active"
      ><i class="block paper clipboard list icon "></i>Orders In</a>
      <a
        routerLink="suppliers" class="item"
        [class.active]="router.isActive('/suppliers', false)"
        routerLinkActive="active"
      ><i class="block paper warehouse icon "></i>Suppliers</a>
      <a
        routerLink="suppliers-orders" class="item"
        [class.active]="router.isActive('/suppliers-orders', false)"
        routerLinkActive="active"
      ><i class="block paper truck icon "></i>Orders to<br />Suppliers</a>
      <a
        routerLink="products" class="item"
        [class.active]="router.isActive('/products', false)"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      ><i class="block paper boxes icon "></i>Products</a>
      <a routerLink="stock-products"
         class="item"
         [class.active]="router.isActive('/stock-products', false)"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: true }"
      ><i class="block paper pallet icon "></i>Stock</a>
      <a routerLink="/signout" class="item" routerLinkActive="active-sidebar"><i class="block sign out icon"></i> Sign out</a>
    </ng-container>
    <ng-container *ngIf="!(signedin$ | async)">
<!--      TODO REMOVE -->
<!--      <a routerLink="/orders" class="item" routerLinkActive="active"><i class="block paper plane outline icon "></i> Orders</a>-->
      <a
        routerLink="/signin" class="ui item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true}"
      ><i class="block sign in icon"></i> Sign in</a>
<!--      <a routerLink="/signup" class="ui item" routerLinkActive="active"><i class="block user outline icon"></i> Sign up</a>-->
    </ng-container>

<!--    <a class="item">-->
<!--      <i class="block layout icon"></i>-->
<!--      Topics-->
<!--    </a>-->
<!--    <a class="item">-->
<!--      <i class="smile icon"></i>-->
<!--      Friends-->
<!--    </a>-->

  </div>

  <div class="ui pushed-content">
    <router-outlet></router-outlet>
    <notifier-container></notifier-container>
    <div class="ui inverted vertical footer segment">
      <div class="ui container">
        Scotclans &copy; Copyright {{ d.getFullYear() }}
      </div>
    </div>
  </div>

<!-- Site content !-->

