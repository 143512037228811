<div (click)="onDismissClick()" class="ui dimmer visible active">
  <div (click)="$event.stopPropagation()" class="ui modal visible active">
    <i class="close icon" (click)="onDismissClick()"></i>
    <div class="header">
      <ng-content select="[modalTitle]"></ng-content>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="actions actions-default">
      <button (click)="onDismissClick()" class="ui button primary">Ok</button>
    </div>
    <div class="actions">
      <ng-content select="[modalFooter]"></ng-content>
    </div>
  </div>
</div>
