import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
      // if (req.method === 'PUT') {
      //   req = req.clone({
      //     setHeaders: {
      //       'Access-Control-Allow-Origin': '*',
      //       'Access-Control-Allow-Headers':  'Origin, X-Requested-With, Content-Type, Accept',
      //       'Access-Control-Allow-Methods':  'OPTIONS, PUT',
      //       'Content-Type': 'application/json; charset=UTF-8',
      //       Authorization: `Bearer ${currentUser.token}`,
      //     }
      //   });
      // } else {
      //   req = req.clone({
      //     setHeaders: {
      //       Authorization: `Bearer ${currentUser.token}`
      //     }
      //   });
      // }
      req = req.clone({
          setHeaders: {
            // TODO: CHANGE LIVE TO BEARER
            Authorization: `Basic c2NvdGNsYW5zLW5ldzpnaXJsIG1peGVkIHJlYWRpbmc=`,
            // Authorization: `Bearer ${currentUser.token}`
          }
        });
    } else {
      // TODO: TO REMOVE LIVE
      req = req.clone({
        setHeaders: {
          Authorization: `Basic c2NvdGNsYW5zLW5ldzpnaXJsIG1peGVkIHJlYWRpbmc=`,
        }
      });
    }
    console.log('req', req)

    return next.handle(req);
    // if (req.url.includes('scotclans')) {
    //   return next.handle(req);
    // }
    // const modifiedReq = req.clone({
    //   withCredentials: true
    // });
    // return next.handle(modifiedReq);
      // .pipe(
      //   tap((val) => {
      //     if (val.type === HttpEventType.Sent) {
      //       console.log('req sent to server');
      //     }
      //     if (val.type === HttpEventType.Response) {
      //       console.log('got response from api', val);
      //     }
      //   })
      // );
  }
}
