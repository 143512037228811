import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  authForm = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      Validators.pattern(/^[a-z0-9]+$/)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(50)
    ])
  });
  onAuthLoading = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.authForm.invalid) {
      return;
    }
    this.onAuthLoading = true;
    this.authService.signin(this.authForm.value)
      .subscribe({
        next: (response) => {
          if(response.allowed === false) {
            this.onAuthLoading = false;
            this.authForm.setErrors({
              invalidRole: true
            });
          } else {
            this.onAuthLoading = false;
            this.router.navigateByUrl('/orders');
          }
        },
        error: (err) => {
          // console.log('err', err)
          this.onAuthLoading = false;
          if (!err.status) {
            if(err.statusText && err.statusText === 'Unknown Error') {
              this.authForm.setErrors({
                noCORS: true
              });
            } else {
              this.authForm.setErrors({
                noConnection: true
              });
            }
          } else if (err.error.username || err.error.password) {
            this.authForm.setErrors({
              wrongCredentials: true
            });
          } else if (err.error.code === '[jwt_auth] incorrect_password' || err.error.code === '[jwt_auth] invalid_username') {
            this.authForm.setErrors({
              wrongCredentials: true
            });
          } else {
            this.authForm.setErrors({
              unknownError: true
            });
          }
        }
      });
  }

}

// git remote set-url origin https://george@git.civiccomputing.com/scotclans/scotclans-admin.git
//

