<div class="field">
<!--  <label>{{ label }}</label>-->

  <div class="">
    <div
      class="field"
      [ngClass]="{'error': showErrors()}"
    >
      <input
        [type]="type"
        [formControl]="control"
        placeholder="{{ label }}"
      />
    </div>
  </div>
  <div *ngIf="showErrors()" class="ui pointing red basic label">
    <p *ngIf="control.errors?.required">
      Value is required
    </p>
    <p *ngIf="control.errors?.minlength">
      Value must be at least {{ control.errors.minlength.requiredLength }} characters
    </p>
    <p *ngIf="control.errors?.maxlength">
      Value cannot be longer than {{ control.errors.maxlength.requiredLength }} characters
    </p>
    <p *ngIf="control.errors?.pattern">
      Invalid Format
    </p>
    <p *ngIf="control.errors?.nonUniqueUsername">
      Username is taken
    </p>
    <p *ngIf="control.errors?.noConnection">
      No internet connection
    </p>
  </div>
</div>

